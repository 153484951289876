import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from "store/user/actions";
import { storage as LocalStorage } from "services/config/storage";
import Loader from "components/Loader/Loader";
import Header from "../../components/Header";
import { Analytics } from "services/analytics";

import './B2BLogin.scss'

const B2BLogin = ({ b2bUserLoginRequest, history,location }) => {

    let [error, setError] = useState(null)

    let paramUrl = new URLSearchParams(location.search);
    let userData = paramUrl.get("user_data");

    useEffect(() => {
        let loginFunc = async () => {
            let payload = {
                user_data: userData
            }
            let res = await b2bUserLoginRequest(payload)
            if (!res?.auth_token) {
                setError(`Unable to process your request at the moment. Please contact your administrator.`)
            }
        }

        if (userData) {
            loginFunc()
        }

    }, [])

    useEffect(() => {
        if (LocalStorage.fetch.defaultChild()?.id){
            Analytics.onLogin()
             history.push('/')
            }
    }, [LocalStorage.fetch.defaultChild()?.id])

    return (
        <div className='b2b_login_main_container'>
            <Header/>
            <div className='b2b_login_container'>
            {
                error ?
                    <div className='error_container'>
                        {error}
                    </div>
                    :
                    <div className='loading_container'>
                        <Loader/>
                    </div>
            }
            </div>
        </div>
    )
}

const connectedComponent = connect(
    ({ user }) => ({ ...user }),
    (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(B2BLogin);

export default connectedComponent;