const grades = [
        {
            "value": "f14343ba-9e6c-4e4f-97fb-e640cedd6b70",
            "label": "Nursery",
            "age_group": "3-4"
        },
        {
            "value": "acb0b270-0c1b-46c6-a62c-466efda9855e",
            "label": "LKG",
            "age_group": "4-5"
        },
        {
            "value": "f8d3de64-2e38-4e61-aa5e-d05a9b6e79ca",
            "label": "UKG",
            "age_group": "5-6"
        },
        {
            "value": "045c7903-d496-45b8-8999-921f10212178",
            "label": "Grade 1",
            "age_group": "6-7"
        },
        {
            "value": "36805b5a-75d0-4943-976a-9da0ba911c7c",
            "label": "Grade 2",
            "age_group": "7-8"
        },
        {
            "value": "6dfc9fd0-2932-4b5b-9a7b-eb533929fa5b",
            "label": "Grade 3",
            "age_group": "8-9"
        },
        {
            "value": "0ebfd04f-86d4-4204-8f45-a122cacbb389",
            "label": "Grade 4",
            "age_group": "9-10"
        },
        {
            "value": "df8d3b07-95b2-4903-a35b-5c77ab10bf72",
            "label": "Grade 5",
            "age_group": "10-11"
        },
        {
            "value": "cfe7b2db-5ddb-4453-8fc9-19608cf44cd4",
            "label": "Grade 6",
            "age_group": "11-12"
        },
        {
            "value": "f8327420-6ca9-453b-819e-f0a506c34cc5",
            "label": "Grade 7",
            "age_group": "12-13"
        },
        {
            "value": "95240892-6fbb-4447-9f0d-25ae082e3a44",
            "label": "Grade 8",
            "age_group": "13-14"
        }
    ]

export default { grades };
